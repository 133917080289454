<template>
  <ed-table
  :key="$root.$session.versao"
     strModel="System/Cliente"
    :headers="arrayHeaders"
    :rows="arrayRows"
    :pagination="objPagination"
    :filter="formFiltros"
    :loading="loading"
    @register="onRegister"
    @edit="onEdit"
    @search="getCliente"
  />
</template>

<script>
import { EdTable } from "@/components/common/form";
export default {
  name: "EdModelo",
  props: {
  },
  components: {
    EdTable,
  },
  beforeDestroy() {
    this.arrayHeaders = [];
    this.arrayRows = [];
    this.objPagination = null;
  },
  provide() {
    return {};
  },
  mounted() {
    this.initialize();
  },
  created() {},
  data() {
    return {
      bind: {
        objTipoModelo: null,
        arrayParametros:[]
      },
      loading: false,
      arrayHeaders: [
        // {
        //   text: "Código",
        //   sortable: true,
        //   value: "intId",
        //   width:"100px"
        // },
        {
          text: "Nome",
          sortable: true,
          value: "strNome",
        },
        // {
        //   text: "Banco de Dados",
        //   sortable: true,
        //   value: "strNomeDatabase",
        // },
        // {
        //   text: "Url",
        //   sortable: true,
        //   value: "strUrlDatabase",
        // },
        {
          text: "Responsável",
          sortable: true,
          value: "strResponsavel",
        },
        {
          text: "Email",
          sortable: true,
          value: "strEmail",
        },
        {
          text: "Telefone",
          sortable: true,
          value: "strTelefone",
        },
        // {
        //   text: "Endereço",
        //   sortable: true,
        //   value: "strEndereco",
        //   collapse: 20,
        // },
        {
          text: "Status",
          sortable: true,
          value: "strTipoStatus",
          width:'200px'
        },
      ],
      arrayRows: [],
      objPagination: null,
      formFiltros:{
      }
    };
  },
  watch: {},
  computed: {},
  methods: {
    initialize() {
      this.getCliente()
    },

    onRegister() {
      this.$router.push({
        name: this.$route.name+'.register'
      });
    },

    onEdit(data) {
      this.$router.push({
        name: this.$route.name+'.edit',
        params: { intId: data.intId },
      });
    },

    getCliente() {
      this.loading = true;
      this.arrayRows = [];

      this.$root.$request
        .get("System/Cliente", this.formFiltros)
        .then((objResult) => {
          if (objResult.status == 200) {
            this.objPagination = objResult.result;
            this.loading = false;

            let arrayRows = [];

            objResult.result.data.forEach((objModelo) => {

              let arrayTelefone = []
              let strEndereco = ''
              let strInformacao = '';
              let arrayStatus = []

              if(objModelo.principal){
                strInformacao += '<span style="color:orange" title="Esse cliente possui a base de dados compatilhado com o cliente '+objModelo.principal.strCnpj+' '+objModelo.principal.strNome+'"><b>'+objModelo.principal.strNome+'</b></span>'
              }

              if(objModelo.strNomeDatabase){
                strInformacao += '<span class="ed-full-width">BANCO DE DADOS: '+objModelo.strNomeDatabase+'</span>'
              }

              if(objModelo.strUrlDatabase){
                strInformacao += '<span class="ed-full-width"><a href="'+(process.env.VUE_APP_URL+objModelo.strUrlDatabase)+'" target="_blank"><i class="'+this.$utilidade.getIcone('link')+'" ></i> Acessar Sistema do Cliente</a></span>'
              }

              if(objModelo.boolSystemAdmin){
                arrayStatus.push('<span style="color:green">Acesso master</span>');
              }

              if(!objModelo.boolAtivo){
                arrayStatus.push('<span style="color:red">Cliente inativo</span>');
              }

              if(!objModelo.boolHabilitado){
                arrayStatus.push('<span style="color:orange">Cliente não habilitado</span>');
              }

              if(objModelo.strDataBloqueio){
                arrayStatus.push('<span style="color:#666666">Bloquear acesso em '+this.$utilidade.toDate(objModelo.strDataBloqueio,true)+'</span>');
              }

              if(objModelo.strMensagemMigracao){
                arrayStatus.push('<span style="color:red">'+objModelo.strMensagemMigracao+'</span>');
              }

              if(objModelo.strCelularResponsavel){
                arrayTelefone.push(objModelo.strCelularResponsavel)
              }

              if(objModelo.strTelefoneResponsavel){
                arrayTelefone.push(objModelo.strTelefoneResponsavel)
              }

              if(objModelo.endereco){
                strEndereco = objModelo.endereco.strLogradouro+', '+objModelo.endereco.strNumero

                if(objModelo.endereco.municipio){
                  strEndereco += '<br>'+objModelo.endereco.municipio.strNome+' / '+objModelo.endereco.municipio.strEstado
                }
              }

              let item = {
                intId: objModelo.intId,
                strUrlDatabase:objModelo.strUrlDatabase,
                strNomeDatabase:objModelo.strNomeDatabase,
                strResponsavel:objModelo.strNomeResponsavel,
                strEmail:objModelo.strEmailResponsavel,
                strEndereco:strEndereco,
                strTipoStatus:arrayStatus.join('<br>'),
                strTelefone:arrayTelefone.join('<br>'),
                strNome:'<b>'+ objModelo.strNome+'</b><span class="ed-full-width ed-label">'+objModelo.strCnpj+'</span>'+strInformacao,
                _item: objModelo,
              };

              arrayRows.push(item);
            });

            this.arrayRows = arrayRows;
          }
        });
    },
  },
};
</script>
